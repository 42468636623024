import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    API_RESOURCE_USERS,
} from 'Consts/apiResources';
import { USER_ROLE_TRAINER } from 'Consts/userRoles';
import { ADMIN_USERS_MANAGE } from 'Consts/routes';

import { fromSelectObject, filterKeys } from 'Utils/object';
import { parseQueryToObject } from 'Utils/querystring';
import { getFullName as getUserFullName } from 'Utils/user';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import { TAB_USER_HOMEWORKS } from 'Components/pages/admin/UsersManage/component';

export default class AdminUserHomeworkEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listUsers: PropTypes.func.isRequired,            
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            changeCompleted: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };

    static defaultProps = {
        predefinedState: {},
    };

    state = {
        formState: {},
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data, predefinedState } = this.props;

        if (
            data && JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
            predefinedState && JSON.stringify(predefinedState) !== JSON.stringify(prevProps.predefinedState)
        ) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }
    }

    dataToFormState = data => {
        const { predefinedState } = this.props;

        return {
            ...data,
            ...predefinedState,
            trainerId: data.trainer
                ? { value: data.trainer.id, label: getUserFullName(data.trainer).label }
                : null,
        };
    }

    formStateToData = formState => {
        const { predefinedState } = this.props;

        return {
            ...formState,
            trainerId: fromSelectObject(formState.trainerId),
            deadlineAt: dateToApiFormat(formState.deadlineAt, 'datetime', true),
            isCompleted: formState.isCompleted || false,
            ...predefinedState,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        });
    }

    onCreate = formState => {
        const { actions, location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return actions.create({
            ...this.formStateToData(formState),
            userId: queryObject.userId,
        }).then(response => {
            history.push(
                withVariables(
                    ADMIN_USERS_MANAGE.path,
                    { id: queryObject.userId },
                    { tab: TAB_USER_HOMEWORKS }
                )
            );
        });
    }

    render() {
        const { location, history, actions } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-user-homework-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    controls={[{
                        visible: Boolean(formState.id),
                        title: formState.isCompleted 
                            ? 'Oznacz jako niewykonaną' 
                            : 'Oznacz jako wykonaną',
                        subtitle: formState.isCompleted 
                            ? 'Praca domowa zostanie oznaczona jako niewykonana'
                            : 'Praca domowa zostanie oznaczona jako wykonana',
                        buttonProps: {
                            onClick: () => actions.changeCompleted({ id: formState && formState.id }),
                            children: formState.isCompleted 
                                ? 'Wykonana' 
                                : 'Niewykonana',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'textarea',
                            name: 'description',
                            label: 'Opis pracy',
                        }, {
                            type: 'select',
                            name: 'trainerId',
                            label: 'Trener',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_TRAINER,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'datePicker',
                            name: 'deadlineAt',
                            label: 'Termin wykonana',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}